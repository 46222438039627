
































































import Vue from 'vue';
import { Page } from '@/common/api/matrix';

export default Vue.extend({
  name: 'LogIn',
  props: {
    redirect: {
      type: String,
      default: 'Welcome',
    },
  },
  data() {
    return {
      email: '',
      password: '',

      emailRules: [
        (v: string) => !!v || this.$i18n.t('validation-emailRequired'),
      ],
      passwordRules: [
        (v: string) => !!v || this.$i18n.t('validation-passwordRequired'),
      ],
    };
  },

  methods: {
    async sendLogIn() {
      // There is a validate method for the v-form component, but since it does not have a valid
      // TS type, it shows this as an error. Nevertheless the code functions properly.
      if ((this.$refs.form as unknown as { validate(): boolean })?.validate()) {
        this.$logIn({
          username: this.email,
          password: this.password,
        })
          .then(() => this.$changeRoute(this.redirect as Page));
      }
    },
  },
});
